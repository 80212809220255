import React, { useEffect, useState } from "react"
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import Input from "../../components/Input"
import { EstilosButton, OrdenCompraStyles } from "../../utils/styles"
import { useAlert } from "../../hooks/useAlert"
import Vista from "../../components/Vista"
import { BiPlus } from "react-icons/bi"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { LoadingButton } from "@mui/lab"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useMessages } from "../../hooks/useMessages"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"
import Servo from "../../services/servo"

const VS = OrdenCompraStyles()
const VS2 = EstilosButton()

const Refacciones = ({
  finalizarOrdenServicio,
  refaccionesGuardadas,
  match,
  id
}) => {
  const OrdenCompraStyles = VS()
  const EstilosButton = VS2()
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("")
  const { showAlert } = useAlert()
  const { setMessages } = useMessages()
  const history = useHistory()
  const [producto, setProducto] = useState(null)
  const [empresa, setEmpresa] = useState(null)
  const [sucursal, setSucursal] = useState(null)
  const [refacciones, setRefacciones] = useState([])
  const [loadingB, setLoadingB] = useState(false)
  const editado = !!id
  const operacion = "Salida"

  const handleCategoriaChange = (e) => {
    setCategoriaSeleccionada(e.target.value)
    setProducto(null)
  }

  const changeCantidad = async (_cantidad, pos) => {
    if ((_cantidad && _cantidad > 0) || _cantidad === "") {
      let copyData = [...refacciones]
      copyData[pos].cantidad = _cantidad
      setRefacciones([...copyData])
    }
  }

  const addProducto = async () => {
    if (!empresa) {
      showAlert({
        message: "Seleccione una empresa",
        severity: "error"
      })
      return
    }
    if (!sucursal) {
      showAlert({
        message: "Seleccione una sucursal",
        severity: "error"
      })
      return
    }
    if (!producto) {
      showAlert({
        message: "Seleccione un producto",
        severity: "error"
      })
      return
    } else {
      if (producto) {
        let copyData = [...refacciones]
        let existe = copyData.some(
          (prod) => prod.idProducto === producto.idProducto
        )
        if (!existe) {
          copyData.push({
            ...producto,
            nombreProducto: producto.nombreProducto,
            cantidad: 1
          })
          setRefacciones([...copyData])
        } else {
          showAlert({
            message: "El producto ya ha sido agregado",
            severity: "error"
          })
        }
      } else {
        showAlert({
          message: "Seleccione un producto",
          severity: "error"
        })
      }
    }
  }

  const deleteProduct = (id) => {
    let arr = [...refacciones]
    refacciones.map((product, i) => {
      if (product.idProducto == id) {
        arr.splice(i, 1)
      }
    })
    setRefacciones([...arr])
    showAlert({
      message: "Producto eliminado",
      severity: "success"
    })
  }

  const guardarRefaccionesOrdenServicio = async () => {
    setLoadingB(true)
    let body = {
      productos: refacciones,
      empresa: empresa,
      sucursal: sucursal,
      operacion: operacion
    }

    if (editado) {
      body = { ...body, idOrdenServicio: id }
    }

    let res = await Servo.post(
      "orden-servicios/guardar-refacciones-orden-servicio",
      body,
      "ordenServicios",
      false,
      history
    )
    console.log("Respuesta completa:", res)
    if (res?.cuerpo) {
      setMessages(res?.cuerpo)
    }

    setLoadingB(false)
  }

  const agrupadoPorProducto = refaccionesGuardadas.reduce((acc, refaccion) => {
    const idProducto = refaccion.productos?.idProducto
    if (!acc[idProducto]) {
      acc[idProducto] = []
    }
    acc[idProducto].push(refaccion)
    return acc
  }, {})

  const refaccionesAgrupadasYSumadas = Object.values(agrupadoPorProducto).map(
    (group) => {
      return group.reduce(
        (acc, refaccion) => {
          // Inicializamos acc.productos con los datos de la primera refacción en el grupo
          if (!acc.productos) {
            acc.productos = refaccion.productos
          }
          // Sumamos las cantidades de las refacciones con el mismo idProducto
          acc.cantidad += refaccion.cantidad
          return acc
        },
        { cantidad: 0 }
      )
    }
  )

  return (
    <Vista>
      <div style={{ width: "74%" }}>
        <div
          className={OrdenCompraStyles.container}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          {/* <div className={OrdenCompraStyles.top}>
            <div className={OrdenCompraStyles.title}>
              <p>Refacciones</p>
            </div>
          </div> */}
          <div className={OrdenCompraStyles.formArea} style={{ width: "50%" }}>
            <div className={OrdenCompraStyles.formDataContainer}>
              <Grid
                container
                direction="row"
                sx={{ width: "100%", height: "100%" }}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={OrdenCompraStyles.inputArea}
                >
                  <AutocompleteDeluxe
                    labelToShow="Empresas"
                    labelProp="nombre"
                    nombre="empresas"
                    value={empresa}
                    setDato={setEmpresa}
                    background={"#FFFFFF"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={OrdenCompraStyles.inputArea}
                >
                  <AutocompleteDeluxe
                    labelToShow="Sucursales"
                    labelProp="nombreSucursal"
                    nombre="sucursales"
                    value={sucursal}
                    setDato={setSucursal}
                    background={"#FFFFFF"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px"
                    }
                  }}
                  className={OrdenCompraStyles.inputArea}
                >
                  <Input
                    typeInput={"formSelect"}
                    valor={categoriaSeleccionada}
                    nombre="Categoría"
                    onChange={handleCategoriaChange}
                    data={[
                      "SUSPENSION",
                      "SERVICIO MOTOR",
                      "DIFERENCIAL",
                      "FRENOS",
                      "NEUMATICOS",
                      "INTERIORES",
                      "REFACCIONES ELECTRICAS",
                      "REFACCIONES",
                      "ADITAMENTOS",
                      "TRANSMISION"
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "92px",
                    padding: "0 10px 0 10px"
                  }}
                >
                  {categoriaSeleccionada && (
                    <AutocompleteDeluxe
                      labelToShow="Productos"
                      labelProp="numParte"
                      nombre="productos"
                      value={producto}
                      setDato={setProducto}
                      filtro={{ categoria: categoriaSeleccionada }}
                      background={"#FFFFFF"}
                      key={categoriaSeleccionada}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    "@media (max-width: 600px)": {
                      alignItems: "center"
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    height: "92px",
                    padding: "0 10px 0 10px"
                  }}
                >
                  <Button
                    sx={{
                      width: "100%",
                      height: "41px",
                      backgroundColor: "#157CC1",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      textTransform: "none",
                      marginBottom: "7px"
                    }}
                    onClick={addProducto}
                    variant="contained"
                  >
                    <BiPlus className={EstilosButton.icon} />
                    <p className={EstilosButton.font}>Agregar Producto</p>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>

          {/* Nuevo diseño para mostrar las tablas lado a lado */}
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={12} sm={6}>
              <div
                className={OrdenCompraStyles.top}
                style={{ paddingBottom: "10px" }}
              >
                <div className={OrdenCompraStyles.title}>
                  <p>Refacciones</p>
                </div>
              </div>
              <Paper
                sx={{
                  "@media (max-width: 600px)": {
                    width: "98%",
                    minHeight: "50%"
                  },
                  width: "100%",
                  overflow: "hidden"
                }}
              >
                <TableContainer sx={{ maxHeight: 320, minHeight: 320 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className={OrdenCompraStyles?.tableHead}>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Num. Parte
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Nombre
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Cantidad
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Eliminar
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {refacciones &&
                        refacciones.map((refaccion, i) => {
                          return (
                            <TableRow
                              className={OrdenCompraStyles?.tableRow}
                              key={i}
                            >
                              <TableCell
                                className="rowColor"
                                sx={{ fontFamily: "poppins" }}
                              >
                                {i + 1}
                              </TableCell>
                              <TableCell
                                className="rowColor"
                                sx={{
                                  fontFamily: "poppins",
                                  lineHeight: "13px"
                                }}
                              >
                                {refaccion.numParte}
                              </TableCell>
                              <TableCell
                                className="rowColor"
                                sx={{
                                  fontFamily: "poppins",
                                  lineHeight: "13px"
                                }}
                              >
                                {refaccion.nombreProducto}
                              </TableCell>
                              <TableCell className="rowColor">
                                <div className={OrdenCompraStyles?.quantity}>
                                  <div
                                    className={
                                      OrdenCompraStyles?.quantityContainer
                                    }
                                    style={{ width: "40%" }}
                                  >
                                    <div
                                      className={
                                        OrdenCompraStyles?.quantityArea
                                      }
                                    >
                                      <Input
                                        typeInput="onlyInput"
                                        style={{
                                          textAlign: "center",
                                          padding: "0px",
                                          width: "30px"
                                        }}
                                        typeFormat="number"
                                        valor={refaccion.cantidad}
                                        onChange={(e) =>
                                          changeCantidad(e.target.value, i)
                                        }
                                      />
                                    </div>
                                    <IconButton
                                      onClick={() =>
                                        changeCantidad(
                                          parseInt(refaccion.cantidad) + 1,
                                          i
                                        )
                                      }
                                    >
                                      <AddIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() =>
                                        changeCantidad(
                                          parseInt(refaccion.cantidad) - 1,
                                          i
                                        )
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() =>
                                    deleteProduct(refaccion.idProducto)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <div
                className={OrdenCompraStyles?.buttonAreaContainer}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "20px"
                }}
              >
                <div className={OrdenCompraStyles?.buttonContainer}>
                  <LoadingButton
                    style={
                      refacciones.length <= 0
                        ? { color: "white", background: "#AAAAAA" }
                        : refacciones.some((prod) => prod.cantidad === "")
                        ? { color: "white", background: "#AAAAAA" }
                        : {}
                    }
                    disabled={
                      refacciones.length <= 0 ||
                      refacciones.some((prod) => prod.cantidad === "")
                    }
                    sx={{
                      width: "170px",
                      height: "50px",
                      backgroundColor: "#157CC1",
                      fontFamily: "poppins",
                      borderRadius: "10px",
                      border: "none",
                      fontWeight: "500",
                      fontSize: "14px",
                      letterSpacing: "0.07em",
                      color: "#FFFFFF",
                      marginLeft: "2px",
                      "@media (min-width: 600px)": {
                        marginBottom: "6px"
                      },
                      "&:hover": {
                        backgroundColor: "#1565c0",
                        cursor: "pointer"
                      }
                    }}
                    className={OrdenCompraStyles?.buttonSave}
                    onClick={guardarRefaccionesOrdenServicio}
                  >
                    GUARDAR REFACCIONES
                  </LoadingButton>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div
                className={OrdenCompraStyles.top}
                style={{ paddingBottom: "10px" }}
              >
                <div className={OrdenCompraStyles.title}>
                  <p>Refacciones Guardadas</p>
                </div>
              </div>
              <Paper
                sx={{
                  "@media (max-width: 600px)": {
                    width: "98%",
                    minHeight: "50%"
                  },
                  width: "100%",
                  overflow: "hidden"
                }}
              >
                <TableContainer sx={{ maxHeight: 320, minHeight: 320 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className={OrdenCompraStyles?.tableHead}>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Num. Parte
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Nombre
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Cantidad
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {refaccionesAgrupadasYSumadas.map((refaccion, i) => (
                        <TableRow
                          className={OrdenCompraStyles?.tableRow}
                          key={i}
                        >
                          <TableCell
                            className="rowColor"
                            sx={{ fontFamily: "poppins" }}
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            className="rowColor"
                            sx={{ fontFamily: "pppins", lineHeight: "13px" }}
                          >
                            {refaccion.productos?.numParte}
                          </TableCell>
                          <TableCell
                            className="rowColor"
                            sx={{ fontFamily: "poppins", lineHeight: "13px" }}
                          >
                            {refaccion.productos?.nombreProducto
                              ? refaccion.productos?.nombreProducto
                              : refaccion.productos?.producto?.nombreProducto}
                          </TableCell>
                          <TableCell className="rowColor">
                            <div className={OrdenCompraStyles?.quantity}>
                              <div
                                className={OrdenCompraStyles?.quantityContainer}
                                style={{ width: "40%" }}
                              >
                                <div
                                  className={OrdenCompraStyles?.quantityArea}
                                >
                                  <Input
                                    typeInput="onlyInput"
                                    style={{
                                      textAlign: "center",
                                      padding: "0px",
                                      width: "30px"
                                    }}
                                    typeFormat="number"
                                    valor={refaccion.cantidad}
                                    onChange={(e) =>
                                      changeCantidad(e.target.value, i)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
          {/* Botones de acción */}
          <div
            className={OrdenCompraStyles?.buttonAreaContainer}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "-60px"
            }}
          >
            <div
              className={OrdenCompraStyles?.buttonContainer}
              style={{ width: "50%" }}
            >
              <LoadingButton
                // style={
                //   refacciones.length <= 0
                //     ? { color: "white", background: "#AAAAAA" }
                //     : refacciones.some((prod) => prod.cantidad === "")
                //     ? { color: "white", background: "#AAAAAA" }
                //     : {}
                // }
                // disabled={
                //   refacciones.length <= 0 ||
                //   refacciones.some((prod) => prod.cantidad === "")
                // }
                sx={{
                  width: "170px",
                  height: "50px",
                  backgroundColor: "#157CC1",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                  color: "#FFFFFF",
                  marginLeft: "2px",
                  "@media (min-width: 600px)": {
                    marginBottom: "6px"
                  },
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    cursor: "pointer"
                  }
                }}
                className={OrdenCompraStyles?.buttonSave}
                onClick={() => finalizarOrdenServicio()}
              >
                FINALIZAR ORDEN
              </LoadingButton>
            </div>
          </div>
          {/* <div style={{ marginTop: "-80px", textAlign: "right" }}>


            <div
              className={OrdenCompraStyles?.buttonAreaContainer}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <div className={OrdenCompraStyles?.buttonContainer}>
                <LoadingButton
                  sx={{
                    width: "170px",
                    height: "50px",
                    backgroundColor: "#157CC1",
                    fontFamily: "poppins",
                    borderRadius: "10px",
                    border: "none",
                    fontWeight: "500",
                    fontSize: "14px",
                    letterSpacing: "0.07em",
                    color: "#FFFFFF",
                    marginLeft: "2px",
                    "@media (min-width: 600px)": {
                      marginBottom: "6px",
                    },
                    "&:hover": {
                      backgroundColor: "#1565c0",
                      cursor: "pointer",
                    },
                  }}
                  className={OrdenCompraStyles?.buttonSave}
                  onClick={() => finalizarOrdenServicio()}
                >
                  FINALIZAR ORDEN
                </LoadingButton>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Vista>
  )
}

export default Refacciones
