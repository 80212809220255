import React, { useState, useEffect, useMemo } from "react"
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"
import Input from "../../components/Input"
import AutocompleteDeluxe from "../../components/AutocompleteDelux"
import { EstilosButton, OrdenCompraStyles } from "../../utils/styles"
import { useAlert } from "../../hooks/useAlert"
import Vista from "../../components/Vista"
import { BiPlus } from "react-icons/bi"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { LoadingButton } from "@mui/lab"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useMessages } from "../../hooks/useMessages"
import { v4 as uuidv4 } from "uuid"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import pdfOrdenCompra from "../../pdfs/pdfOrdenCompra"

let VS = OrdenCompraStyles()
let VS2 = EstilosButton()
const Diagnosticos = ({
  diagnosticos,
  setDiagnosticos,
  guardarDiagnostico,
  guardarOrdenCompra,
  ordenesCompra,
  setOrdenesCompra
}) => {
  let OrdenCompraStyles = VS()
  let EstilosButton = VS2()
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("")
  const [categoriaSeleccionadaCompra, setCategoriaSeleccionadaCompra] =
    useState("")
  const { showAlert } = useAlert()
  const { setMessages } = useMessages()
  const history = useHistory()
  const [producto, setProducto] = useState(null)
  const [productoCompra, setProductoCompra] = useState(null)
  const [refaccion, setRefaccion] = useState("")
  const [numeroOrdenSeleccionada, setNumeroOrdenSeleccionada] = useState("")
  const [productosOrdenCompra, setProductosOrdenCompra] = useState([])
  const [isOrdenCompraLocked, setIsOrdenCompraLocked] = useState(false)

  const numerosOrdenes = useMemo(() => {
    if (!Array.isArray(ordenesCompra)) {
      return []
    }
    const numeros = ordenesCompra
      .map((orden) => orden.idOrdenCompra)
      .filter((num) => num)
      .map((num, i) => i + 1)
    return numeros
  }, [ordenesCompra])

  const getOrdenCompra = (_numeroOrdenSeleccionada) => {
    if (!_numeroOrdenSeleccionada) return

    const ordenSeleccionada =
      ordenesCompra[Number(_numeroOrdenSeleccionada) - 1]

    if (!ordenSeleccionada) {
      showAlert({
        message: "El número de orden seleccionado no es válido",
        severity: "error"
      })
      return
    }
    setProductosOrdenCompra(ordenSeleccionada.ordenesCompraRefacciones || [])
    setIsOrdenCompraLocked(true)
  }

  const handleCategoriaChange = (e) => {
    setCategoriaSeleccionada(e.target.value)
    setProducto(null)
  }

  const handleCategoriaChangeCompra = (e) => {
    setCategoriaSeleccionadaCompra(e.target.value)
    setProductoCompra(null)
  }

  const addProduct = () => {
    if (refaccion !== "") {
      const productoExistente = diagnosticos.some(
        (d) => d.producto === refaccion && d.categoria === categoriaSeleccionada
      )

      if (productoExistente) {
        showAlert({
          message: "El producto ya ha sido agregado a la lista de diagnósticos",
          severity: "warning"
        })
      } else {
        let nuevoProducto = {
          refaccion: refaccion,
          categoria: categoriaSeleccionada,
          cantidad: 1,
          uid: uuidv4()
        }
        setDiagnosticos([...diagnosticos, nuevoProducto])
        setProducto(null)
        setRefaccion("")
        console.log(nuevoProducto)
        showAlert({
          message: "Producto agregado exitosamente",
          severity: "success"
        })
      }
    } else {
      showAlert({
        message: "No hay Producto seleccionado",
        severity: "warning"
      })
    }
  }

  const addProductCompra = () => {
    if (isOrdenCompraLocked) {
      showAlert({
        message:
          "No se puede agregar productos a una orden de compra bloqueada",
        severity: "warning"
      })
      return
    }

    if (productoCompra) {
      const productoExistenteCompra = productosOrdenCompra.some(
        (c) =>
          c.producto === productoCompra &&
          c.categoria === categoriaSeleccionadaCompra
      )
      if (productoExistenteCompra) {
        showAlert({
          message: "El producto ya ha sido agregado a la orden de compra",
          severity: "warning"
        })
      } else {
        let nuevoProductoCompra = {
          producto: productoCompra,
          idProducto: productoCompra.idProducto,
          categoria: categoriaSeleccionadaCompra,
          cantidad: 1,
          precio: "",
          subtotal: 0
        }
        setProductosOrdenCompra([...productosOrdenCompra, nuevoProductoCompra])
        setProductoCompra(null)
        showAlert({
          message: "Producto agregado a la orden de compra exitosamente",
          severity: "success"
        })
      }
    } else {
      showAlert({
        message: "No hay Producto seleccionado para la orden de compra",
        severity: "warning"
      })
    }
  }

  const changeCantidad = (_cantidad, pos) => {
    const copyData = [...diagnosticos]
    copyData[pos].cantidad = _cantidad
    setDiagnosticos([...copyData])
  }

  const changeCantidadCompra = (cantidad, index) => {
    let nuevasOrdenesCompra = [...productosOrdenCompra]
    nuevasOrdenesCompra[index].cantidad = cantidad
    nuevasOrdenesCompra[index].subtotal =
      nuevasOrdenesCompra[index].precio * cantidad
    setOrdenesCompra(nuevasOrdenesCompra)
  }

  const changePrecioCompra = (precio, index) => {
    let nuevasOrdenesCompra = [...productosOrdenCompra]
    nuevasOrdenesCompra[index].precio = precio
    nuevasOrdenesCompra[index].subtotal =
      precio * nuevasOrdenesCompra[index].cantidad
    setOrdenesCompra(nuevasOrdenesCompra)
  }

  const deleteProduct = (uid) => {
    let newDiagnosticos = diagnosticos.filter(
      (producto) => producto.uid !== uid
    )
    setDiagnosticos(newDiagnosticos)
    showAlert({
      message: "Producto eliminado correctamente",
      severity: "success"
    })
    return
  }

  const deleteProductOrdenCompra = (idProducto) => {
    if (isOrdenCompraLocked) {
      showAlert({
        message:
          "No se puede eliminar productos de una orden de compra bloqueada",
        severity: "warning"
      })
      return
    }

    let newOrdenCompra = productosOrdenCompra.filter(
      (productoCompra) => productoCompra.idProducto !== idProducto
    )
    setProductosOrdenCompra(newOrdenCompra)
    showAlert({
      message: "Producto eliminado correctamente",
      severity: "success"
    })
  }

  const handleProveedorChange = (proveedor, index) => {
    let nuevasOrdenesCompra = [...productosOrdenCompra]
    nuevasOrdenesCompra[index].proveedor = proveedor
    nuevasOrdenesCompra[index].idProveedor = proveedor.idProveedor
    setOrdenesCompra(nuevasOrdenesCompra)
  }

  const handleGeneratePDF = async () => {
    if (!numeroOrdenSeleccionada) {
      showAlert({
        message: "Por favor selecciona una orden de compra",
        severity: "warning"
      })
      return
    }

    const ordenSeleccionada = ordenesCompra[Number(numeroOrdenSeleccionada) - 1]

    if (!ordenSeleccionada) {
      showAlert({
        message: "El número de orden seleccionado no es válido",
        severity: "error"
      })
      return
    }

    try {
      console.log(ordenSeleccionada)
      let pdfBlob = await pdfOrdenCompra(ordenSeleccionada)
      const url = window.URL.createObjectURL(pdfBlob)
      const a = document.createElement("a")
      a.href = url
      a.download = `OrdenCompra_${numeroOrdenSeleccionada}.pdf`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    } catch (error) {
      showAlert({
        message: "Hubo un error al generar el PDF",
        severity: "error"
      })
      console.error("Error generating PDF:", error)
    }
  }

  return (
    <Vista>
      <div style={{ display: "flex", flexDirection: "row", width: "80%" }}>
        <div style={{ width: "50%" }}>
          {/* División izquierda */}
          {/* Código existente */}
          <div className={OrdenCompraStyles.container}>
            <div className={OrdenCompraStyles.top}>
              <div className={OrdenCompraStyles.title}>
                <p>Diagnosticos / Requisiciones</p>
              </div>
            </div>
            <div className={OrdenCompraStyles.formArea}>
              <div className={OrdenCompraStyles.formDataContainer}>
                <Grid
                  container
                  direction="row"
                  sx={{ width: "100%", height: "100%" }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      "@media (min-width: 600px)": {
                        paddingRight: "10px"
                      }
                    }}
                    className={OrdenCompraStyles.inputArea}
                  >
                    <Input
                      typeInput={"formSelect"}
                      valor={categoriaSeleccionada}
                      nombre="Categoría"
                      onChange={handleCategoriaChange}
                      data={[
                        "SUSPENSION",
                        "SERVICIO MOTOR",
                        "DIFERENCIAL",
                        "FRENOS",
                        "NEUMATICOS",
                        "INTERIORES",
                        "REFACCIONES ELECTRICAS",
                        "REFACCIONES",
                        "ADITAMENTOS",
                        "TRANSMISION",
                        "OTROS"
                      ]}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "92px",
                      padding: "0 10px 0 10px"
                    }}
                  >
                    <Input
                      typeInput={"form"}
                      valor={refaccion}
                      nombre="Producto"
                      onChange={(e) => setRefaccion(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      "@media (max-width: 600px)": {
                        alignItems: "center"
                      },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      height: "92px",
                      padding: "0 10px 0 10px"
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        height: "41px",
                        backgroundColor: "#157CC1",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "none",
                        marginBottom: "7px"
                      }}
                      onClick={addProduct}
                      variant="contained"
                    >
                      <BiPlus className={EstilosButton.icon} />
                      <p className={EstilosButton.font}>Agregar</p>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={OrdenCompraStyles.formAreaTable}>
              <Paper
                sx={{
                  "@media (max-width: 600px)": {
                    width: "98%",
                    minHeight: "50%"
                  },
                  width: "100%",
                  overflow: "hidden"
                }}
              >
                <TableContainer sx={{ maxHeight: 320 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className={OrdenCompraStyles?.tableHead}>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Nombre
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Tipo
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Cantidad
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Eliminar
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {diagnosticos &&
                        diagnosticos.length > 0 &&
                        diagnosticos.map((diagnostico, index) => (
                          <TableRow
                            className={OrdenCompraStyles?.tableRow}
                            key={index}
                          >
                            <TableCell
                              className="rowColor"
                              sx={{ fontFamily: "poppins" }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              className="rowColor"
                              sx={{ fontFamily: "poppins", lineHeight: "13px" }}
                            >
                              {/* {diagnostico?.producto?.nombreProducto ? diagnostico?.producto?.nombreProducto : diagnostico?.nombreProducto} */}
                              {diagnostico?.refaccion}
                            </TableCell>
                            <TableCell
                              className="rowColor"
                              sx={{ fontFamily: "poppins", lineHeight: "13px" }}
                            >
                              {diagnostico?.categoria}
                            </TableCell>
                            <TableCell className="rowColor">
                              <div className={OrdenCompraStyles?.quantity}>
                                <div
                                  className={
                                    OrdenCompraStyles?.quantityContainer
                                  }
                                  style={{ width: "40%" }}
                                >
                                  <div
                                    className={OrdenCompraStyles?.quantityArea}
                                  >
                                    <Input
                                      typeInput="onlyInput"
                                      style={{
                                        textAlign: "center",
                                        padding: "0px",
                                        width: "30px"
                                      }}
                                      typeFormat="number"
                                      valor={diagnostico?.cantidad || ""}
                                      onChange={(e) => {
                                        changeCantidad(e.target.value, index)
                                      }}
                                    />
                                  </div>
                                  <IconButton
                                    onClick={() =>
                                      changeCantidad(
                                        parseInt(diagnostico?.cantidad || 0) +
                                          1,
                                        index
                                      )
                                    }
                                  >
                                    <AddIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      changeCantidad(
                                        parseInt(diagnostico?.cantidad || 0) -
                                          1,
                                        index
                                      )
                                    }
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="error"
                                component="span"
                                onClick={() => deleteProduct(diagnostico.uid)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>

            <div className={OrdenCompraStyles?.buttonAreaContainer}>
              <div
                className={OrdenCompraStyles?.buttonContainer}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "10px"
                }}
              >
                <LoadingButton
                  // loading={loadingB}
                  // onClick={guardar}
                  sx={{
                    width: "170px",
                    height: "50px",
                    backgroundColor: "#157CC1",
                    fontFamily: "poppins",
                    borderRadius: "10px",
                    border: "none",
                    fontWeight: "500",
                    fontSize: "14px",
                    letterSpacing: "0.07em",
                    color: "#FFFFFF",
                    marginLeft: "2px",
                    "@media (min-width: 600px)": {
                      marginBottom: "6px"
                    },
                    "&:hover": {
                      backgroundColor: "#1565c0",
                      cursor: "pointer"
                    }
                  }}
                  className={OrdenCompraStyles?.buttonSave}
                  onClick={() => guardarDiagnostico(diagnosticos)}
                >
                  GUARDAR DIAGNOSTICO
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "50%" }}>
          {/* División derecha (código existente no se muestra aquí) */}
          <div className={OrdenCompraStyles.container}>
            <div className={OrdenCompraStyles.top}>
              <div className={OrdenCompraStyles.title}>
                <p>Orden Compra</p>
              </div>
            </div>
            <div className={OrdenCompraStyles.formArea}>
              <div className={OrdenCompraStyles.formDataContainer}>
                <Grid
                  container
                  direction="row"
                  sx={{ width: "100%", height: "100%" }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={8}
                    sx={{
                      "@media (min-width: 600px)": {
                        paddingRight: "10px"
                      }
                    }}
                    className={OrdenCompraStyles.inputArea}
                  >
                    <Input
                      typeInput={"formSelect"}
                      valor={categoriaSeleccionadaCompra}
                      nombre="Categoría"
                      onChange={handleCategoriaChangeCompra}
                      data={[
                        "SUSPENSION",
                        "SERVICIO MOTOR",
                        "DIFERENCIAL",
                        "FRENOS",
                        "NEUMATICOS",
                        "INTERIORES",
                        "REFACCIONES ELECTRICAS",
                        "REFACCIONES",
                        "ADITAMENTOS",
                        "TRANSMISION"
                      ]}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px"
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        height: "41px",
                        backgroundColor: "#157CC1",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "none",
                        top: "20px"
                      }}
                      onClick={handleGeneratePDF}
                      variant="contained"
                    >
                      <PictureAsPdfIcon sx={{ marginRight: "10px" }} />
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    sx={{
                      "@media (min-width: 600px)": {
                        paddingRight: "10px"
                      }
                    }}
                    className={OrdenCompraStyles.inputArea}
                  >
                    <Input
                      typeInput={"formSelect"}
                      valor={numeroOrdenSeleccionada || ""}
                      nombre="No. Orden"
                      onChange={(e) => {
                        setNumeroOrdenSeleccionada(e.target.value)
                        getOrdenCompra(e.target.value)
                      }}
                      data={numerosOrdenes}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "92px",
                      padding: "0 10px 0 10px"
                    }}
                  >
                    {categoriaSeleccionadaCompra && (
                      <AutocompleteDeluxe
                        labelToShow="Productos"
                        labelProp="numParte"
                        nombre="productos"
                        value={productoCompra}
                        setDato={setProductoCompra}
                        filtro={{ categoria: categoriaSeleccionadaCompra }}
                        background={"#FFFFFF"}
                        key={categoriaSeleccionadaCompra}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      "@media (max-width: 600px)": {
                        alignItems: "center"
                      },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      // marginBottom: "4px",
                      // height:"33%",
                      height: "92px",
                      // backgroundColor: "red",
                      padding: "0 10px 0 10px"
                    }}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        height: "41px",
                        backgroundColor: "#157CC1",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "none",
                        marginBottom: "7px"
                      }}
                      onClick={addProductCompra}
                      variant="contained"
                    >
                      <BiPlus className={EstilosButton.icon} />
                      <p className={EstilosButton.font}>Agregar</p>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className={OrdenCompraStyles.formAreaTable}>
              <Paper
                sx={{
                  "@media (max-width: 600px)": {
                    width: "98%",
                    minHeight: "50%"
                  },
                  width: "100%",
                  overflow: "hidden"
                }}
              >
                <TableContainer sx={{ maxHeight: 320 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className={OrdenCompraStyles?.tableHead}>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          #
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Num. Parte
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Nombre
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Precio
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Cantidad
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Subtotal
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Proveedor
                        </TableCell>
                        <TableCell
                          className="headColor"
                          sx={{ fontFamily: "poppins" }}
                        >
                          Eliminar
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productosOrdenCompra.length > 0 &&
                        productosOrdenCompra.map((product, i) => {
                          return (
                            <TableRow
                              className={OrdenCompraStyles?.tableRow}
                              key={i}
                            >
                              <TableCell
                                className="rowColor"
                                sx={{ fontFamily: "poppins" }}
                              >
                                {i + 1}
                              </TableCell>
                              <TableCell
                                className="rowColor"
                                sx={{
                                  fontFamily: "poppins",
                                  lineHeight: "13px"
                                }}
                              >
                                {product?.productos?.numParte}
                              </TableCell>
                              <TableCell
                                className="rowColor"
                                sx={{
                                  fontFamily: "poppins",
                                  lineHeight: "13px"
                                }}
                              >
                                {product?.productos?.nombreProducto
                                  ? product?.productos?.nombreProducto
                                  : product?.producto.nombreProducto}
                              </TableCell>
                              <TableCell className="rowColor">
                                <div className={OrdenCompraStyles?.quantity}>
                                  <div
                                    className={
                                      OrdenCompraStyles?.quantityContainer
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      className={
                                        OrdenCompraStyles?.quantityArea
                                      }
                                      style={{ width: "80px" }}
                                    >
                                      <Input
                                        disabled={isOrdenCompraLocked}
                                        typeInput="onlyInput"
                                        style={{
                                          textAlign: "center",
                                          padding: "0px"
                                        }}
                                        typeFormat="numberDecimal"
                                        valor={product.precio || ""}
                                        onChange={(e) => {
                                          changePrecioCompra(e.target.value, i)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell className="rowColor">
                                <div className={OrdenCompraStyles?.quantity}>
                                  <div
                                    className={
                                      OrdenCompraStyles?.quantityContainer
                                    }
                                    style={{ width: "40%" }}
                                  >
                                    <div
                                      className={
                                        OrdenCompraStyles?.quantityArea
                                      }
                                    >
                                      <Input
                                        typeInput="onlyInput"
                                        disabled={isOrdenCompraLocked}
                                        style={{
                                          textAlign: "center",
                                          padding: "0px",
                                          width: "30px"
                                        }}
                                        typeFormat="number"
                                        valor={product.cantidad}
                                        onChange={(e) => {
                                          changeCantidadCompra(
                                            e.target.value,
                                            i
                                          )
                                        }}
                                      />
                                    </div>
                                    <IconButton
                                      disabled={isOrdenCompraLocked}
                                      onClick={() =>
                                        changeCantidadCompra(
                                          parseInt(product.cantidad) + 1,
                                          i
                                        )
                                      }
                                    >
                                      <AddIcon />
                                    </IconButton>
                                    <IconButton
                                      disabled={isOrdenCompraLocked}
                                      onClick={() =>
                                        changeCantidadCompra(
                                          parseInt(product.cantidad) - 1,
                                          i
                                        )
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                className="rowColor"
                                sx={{
                                  fontFamily: "poppins",
                                  lineHeight: "13px"
                                }}
                              >
                                {product.precio * product.cantidad + "$"}
                              </TableCell>
                              <TableCell
                                className="rowColor"
                                sx={{ width: 150 }}
                              >
                                <AutocompleteDeluxe
                                  labelProp="nombre"
                                  disabled={isOrdenCompraLocked}
                                  nombre="proveedores"
                                  dato={product.proveedores}
                                  setDato={(proveedor) =>
                                    handleProveedorChange(proveedor, i)
                                  }
                                  background={"#FFFFFF"}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  disabled={isOrdenCompraLocked}
                                  onClick={() =>
                                    deleteProductOrdenCompra(product.idProducto)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            <div
              className={OrdenCompraStyles?.buttonAreaContainer}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "20px"
              }}
            >
              <div className={OrdenCompraStyles?.buttonContainer}>
                <LoadingButton
                  style={
                    ordenesCompra.length <= 0
                      ? { color: "white", background: "#AAAAAA" }
                      : ordenesCompra.some((prod) => prod.cantidad == "")
                      ? { color: "white", background: "#AAAAAA" }
                      : {}
                  }
                  disabled={
                    ordenesCompra.length <= 0
                      ? true
                      : ordenesCompra.some((prod) => prod.cantidad == "")
                      ? true
                      : false
                  }
                  // loading={loadingB}
                  sx={{
                    width: "170px",
                    height: "50px",
                    backgroundColor: "#157CC1",
                    fontFamily: "poppins",
                    borderRadius: "10px",
                    border: "none",
                    fontWeight: "500",
                    fontSize: "14px",
                    letterSpacing: "0.07em",
                    color: "#FFFFFF",
                    marginLeft: "2px",
                    "@media (min-width: 600px)": {
                      marginBottom: "6px"
                    },
                    "&:hover": {
                      backgroundColor: "#1565c0",
                      cursor: "pointer"
                    }
                  }}
                  className={OrdenCompraStyles?.buttonSave}
                  onClick={() => guardarOrdenCompra(ordenesCompra)}
                >
                  GUARDAR ORDEN DE COMPRA
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Vista>
  )
}

export default Diagnosticos
